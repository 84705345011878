var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"common-wrap"},[_c('headNav',{attrs:{"active":"3"}}),_c('div',{staticClass:"common-contents"},[_c('div',{staticClass:"banner"},[(_vm.$i18n.locale == 'zh')?_c('img',{staticClass:"img",attrs:{"src":require("@/assets/version2/other-14.png"),"alt":"","srcset":""}}):_c('img',{staticClass:"img",attrs:{"src":require("@/assets/version2/otherEn-14.png"),"alt":"","srcset":""}})]),_c('div',{staticClass:"contents"},[_c('div',{staticClass:"dot dot-right"}),_c('div',{staticClass:"dot dot-left"}),_c('div',{staticClass:"w1200 main"},[_c('div',{staticClass:"bg-line"}),_c('div',{staticClass:"contents-hz flex"},[_c('div',{staticClass:"item-left"},_vm._l((_vm.journalListL),function(item,index){return _c('div',{key:index,staticClass:"item-left-img"},[_c('div',{staticClass:"date-1",style:({
                        backgroundImage:
                            'url(' +
                            require('../assets/version2/dsj-' +
                            item.year +
                            '.png') +
                            ')',
                        }),on:{"click":function($event){return _vm.incident(item)}}},[_c('div',{staticClass:"date-sj",class:item.id == _vm.listIndex?'rotating-element' : ''})])])}),0),_c('div',{staticClass:"item-right"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"mb-80"},[(item.url)?_c('div',{staticClass:"item-right-top"},[_c('div',{staticClass:"bg-right"}),_c('img',{staticClass:"bg-img",attrs:{"src":require('../assets/version2024/' +item.url ),"alt":""}}),_c('div',{staticClass:"en-img"})]):_vm._e(),(_vm.$i18n.locale == 'zh')?_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.title))]):_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.titleEn))]),(_vm.$i18n.locale == 'zh')?_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.name))]):_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.nameEn))])])}),0)])])])]),_c('footNav',{attrs:{"type":"sub"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }